<template>
  <b-container fluid>
    <div class="container page">
      <div class="content" v-html="content"></div>
    </div>
  </b-container>
</template>
<script>
import PagesService from '@/_services/pages.service';

const pagesService = new PagesService();
export default {
  name: 'home',
  components: {},
  data() {
    return {
      content: '',
    };
  },
  created() {
    if (pagesService.isCached('CMS_strona-glowna')) {
      this.content = pagesService.getCached('CMS_strona-glowna').content;
    } else {
      this.$store.state.loading = true;
    }
    pagesService.getCmsPage('strona-glowna').then((page) => {
      this.content = page.content;
      this.$store.state.loading = false;
    });
  },
};
</script>
<style>
.page img {
  max-width: 100% !important;
}
</style>
